import { Global, css } from '@emotion/react';
import React from 'react';

export const colors = {
    primary: '#179DD9',
    black: '#1D1D1D',
    white: '#FFFFFF',
    grey: '#4F4F4F',
    offWhite: '#EFEFEF',
};

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '400px',
    tablet: '750px',
    laptop: '1020px',
    laptopL: '1360px',
    desktop: '1580px',
    desktopL: '1820px',
};

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop}) `,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktopL})`,
};

export const GlobalStyles: React.FC = () => {
    return (
        <Global
            styles={css`
                html {
                    box-sizing: border-box;
                    font-size: 100%;
                    overflow-x: hidden;
                    -ms-text-size-adjust: 100%;
                    -webkit-text-size-adjust: 100%;
                    scroll-behavior: smooth;

                    button {
                        cursor: pointer;
                    }
                }

                body {
                    font-family: 'Inter';
                    margin: 0em;
                }
                .shadow {
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 37px 0px,
                        rgba(0, 0, 0, 0.3) 0px 8px 29px -20px;
                }
                a {
                    text-decoration: none;
                }
                h2 {
                    text-transform: uppercase;
                    margin: 0em 0em 1em;
                    font-size: 48px;
                    color: ${colors.black};
                    font-weight: 700;
                    span {
                        color: ${colors.primary};
                    }
                }
                select:focus {
                    outline: 2.5px solid #00bcd4; /* oranges! yey */
                    /*  */

                    border-radius: 3px;
                }
                input[type='time']:focus {
                    outline: 2.5px solid #00bcd4; /* oranges! yey */
                    /*  */

                    border-radius: 3px;
                }
                input[type='text']:focus {
                    outline: 2.5px solid #00bcd4; /* oranges! yey */
                    /*  */

                    border-radius: 3px;
                }
                input[type='text']:focus::placeholder,
                input[type='tel']:focus::placeholder,
                input[type='email']:focus::placeholder,
                textarea:focus::placeholder {
                    color: #00bcd4;
                }
                input[type='email']:focus {
                    outline: 2.5px solid #00bcd4; /* oranges! yey */
                    /*  */

                    border-radius: 3px;
                }
                input[type='tel']:focus {
                    outline: 2.5px solid #00bcd4; /* oranges! yey */
                    /*  */

                    border-radius: 3px;
                }
                input[type='date']:focus {
                    outline: 2.5px solid #00bcd4; /* oranges! yey */
                    /*  */

                    border-radius: 3px;
                }
                input[type='date']::-webkit-calendar-picker-indicator {
                    position: relative;
                    right: 0.6em;
                    fill: red;
                    top: 0.32em;
                    height: 30px;

                    -webkit-appearance: none;
                }
                textarea {
                    font-family: 'inter';
                }
                textarea:focus {
                    outline: 2px solid #00bcd4; /* oranges! yey */
                }
                @media ${device.desktop} {
                    h2 {
                        font-size: 54px;
                    }
                }
            `}
        />
    );
};
