import { GlobalStyles } from '@/layout/GlobalStyles';

export const DefaultLayout: React.FC = ({ children }) => {
    return (
        <div id="layout">
            <GlobalStyles />
            {children}
        </div>
    );
};
